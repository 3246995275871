import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: 100,
    position: "absolute",
    top: 40,
    right: 10,
    width: 'fit-content',
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  paper: {
    position: "relative"
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    left: 0,
  },
  callButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  dialerContainer: {
   position: "absolute",
   right: 10,

  },
}));


const DialerComponent = ({ phoneNumber, onClose }) => {
  const classes = useStyles();
  const dialerRef = useRef(null);
  const net2PhoneDialerRef = useRef(null);

  useEffect(() => {
    if (window.Net2PhoneDialer) {
      net2PhoneDialerRef.current = new window.Net2PhoneDialer({
        rootHtmlElement: dialerRef.current,
      });

      const subscription = net2PhoneDialerRef.current.subscribe((event) => {
        console.log('Evento do dialer:', event);
      });

      if (phoneNumber) {
       
        setTimeout(() => {
          handleCall();
        }, 2000); 

      }

      return () => {
        subscription.dispose();
        net2PhoneDialerRef.current.dispose();
      };
    } else {
      console.error('Net2PhoneDialer SDK não foi carregado.');
    }
  }, []);

  const formatPhoneNumber = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    if (cleanedNumber.startsWith('55')) {
      return cleanedNumber.slice(2);
    }
    return cleanedNumber;
  };

  const handleCall = async () => {
    const formattedNumber = formatPhoneNumber(phoneNumber);
    console.log('Preparando chamada para:', formattedNumber);
    
    try {
      if (net2PhoneDialerRef.current) {
        await net2PhoneDialerRef.current.placeCall({ to: formattedNumber });
        console.log(`Chamada para ${formattedNumber} preparada. Inicie a chamada na interface.`);
      } else {
        console.error('Net2PhoneDialer não está inicializado.');
      }
    } catch (e) {
      console.error('Erro ao preparar a chamada:', e);
    }
  };

  return (
    <div className={classes.modal}>
      <Paper className={classes.paper}>    
        <div style={{height: "200px", position: "relative"}}>
        <div id="net2phone-dialer" className={classes.dialerContainer} ref={dialerRef}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        </div>
        </div>
        
      </Paper>
    </div>
  );
};

export default DialerComponent;
